// Define un tipo genérico para el entorno

import { Injector } from "@angular/core";
import { ENV } from "../interfaces/interfaces";
import { EncryptionService } from "../services/encryption.service";

export function getEnvironment(): ENV {
    let _x = '12345678901234567890123456789012', _y = '1234567890123456';
    const injector = Injector.create({providers: [{provide: EncryptionService, useClass: EncryptionService, deps: []}]});
    const encryptionService = injector.get(EncryptionService);

    const encryptedEnv: ENV = encryptionService.decrypt(window.selfEnv, _x, _y);
    return encryptedEnv;
}